<template>
    <div class="cnt-hec-logo">
     <router-link :to="{ name: 'dashboard.index'}">
        <div class="icon"><img src="@frontend/images/logo.png" alt="index"/></div>
    </router-link></div>
</template>

<script>
export default {
    name: "Logo"
}
</script>

<style scoped>

</style>
